import { BigNumber } from 'bignumber.js'
import cachios from 'cachios'
import Web3 from 'web3'
import { AbstractProvider } from 'web3-core'

import * as actions from '../actions/tokenActions'
import { TokenProfileSummaryData } from '../components/TokenProfile/tokenProfileReducer'
import { environment } from '../config/settings'
import { TOKEN_CACHE_TTL_SEC } from '../constants'
import { legacyAbortableFetch, legacyApiFetch } from '../helpers/ProxyApiFetch'
import {
  Address,
  CacheBodyRequest,
  CacheResponse,
  Category,
  MarketType,
  TokenPrice,
  TokenSpenders,
  TokenV2,
  TokenV3,
  TokenWithApprovalContext,
  TradeType,
  TrendingTokenV2,
  TrendingTokenV3,
} from '../model'
import {
  getAvailableNetworks,
  getMarketDisplayName,
  getNetworkConfig,
  getTokenAddressFromId,
  getTokenIdFromLocalStorage,
  idAndNetworkMatch,
  isNativeTokenForNetwork,
  setTokenToLocalStorage,
} from '../utils'
import gtmService from './gtmService'
import { store } from './reduxService'
import { checkTokenAllowance } from './transactionService'

export function calculateTokenUsdPrices({
  tokenFrom,
  tokenTo,
}: {
  tokenFrom: TokenV3
  tokenTo: TokenV3
}): {
  fromTokenUsdPrice: BigNumber
  toTokenUsdPrice: BigNumber
} {
  const fromTokenUsdPrice = new BigNumber(tokenFrom.priceUSD)
  const toTokenUsdPrice = new BigNumber(tokenTo.priceUSD)
  return { fromTokenUsdPrice, toTokenUsdPrice }
}

export const searchTokensBySymbol = async (symbol: string, network: string): Promise<TokenV3[]> => {
  if (!symbol) {
    return []
  }

  const searchSymbol = Web3.utils.isAddress(symbol.toLowerCase())
    ? symbol.toLowerCase()
    : symbol.toUpperCase()

  const networksConfigState = store.getState().networksConfig
  const availableNetworks = getAvailableNetworks(networksConfigState).join(',')
  const networkParam = network === 'all' ? `?network=${availableNetworks}` : `?network=${network}`

  const abortableFetchResponse = await legacyAbortableFetch<{ data: TokenV3[] }>(
    environment.getDexGuruAPIV3Url(),
    `/tokens/search/${encodeURIComponent(searchSymbol)}${networkParam}`,
    {},
    'tokenBySymbolAbortController'
  )
  if (!abortableFetchResponse?.response?.data) {
    return []
  }

  return abortableFetchResponse?.response?.data.map((item: TokenV3) => {
    if (item.marketType === MarketType.account) {
      return { ...item, wallet_category: item.wallet_category?.toLowerCase() as Category }
    }

    return item
  })
}

export const getQuoteToken = async (activeToken: TokenV3): Promise<TokenV3 | undefined> => {
  // If active token is a native token for network (e.g. ETH)
  // we need to pick up the one from the fallback list SECONDARY_QUOTE_TOKEN_IDS
  // otherwise we will end up with active token ETH quote token ETH
  const networkConfig = getNetworkConfig(activeToken.network)
  const quoteTokenId = isNativeTokenForNetwork(activeToken)
    ? `${networkConfig.secondary_token_address}-${activeToken.network}`
    : `${networkConfig.primary_token_address}-${activeToken.network}`

  return await refreshToken(quoteTokenId)
}

export const setQuoteToken = async (
  quoteToken: TokenV3,
  library: Web3,
  account?: Address | null,
  network?: string
): Promise<TokenWithApprovalContext> => {
  let quoteTokenWithApprovalContext: TokenWithApprovalContext = quoteToken
  if (account) {
    if (quoteToken.network === network) {
      const zeroXAllowance = await checkTokenAllowance({
        account,
        tokenFrom: quoteToken,
        library,
        spender: TokenSpenders.zeroX,
      })
      const oneInchAllowance = await checkTokenAllowance({
        account,
        tokenFrom: quoteToken,
        library,
        spender: TokenSpenders.oneInch,
      })
      quoteTokenWithApprovalContext = {
        ...quoteToken,
        zeroX: zeroXAllowance,
        oneInch: oneInchAllowance,
      }
    } else {
      quoteTokenWithApprovalContext = {
        ...quoteToken,
        zeroX: { isTokenApproved: null, tokenApproveError: null, tokenAllowance: undefined },
        oneInch: { isTokenApproved: null, tokenApproveError: null, tokenAllowance: undefined },
      }
    }
  }

  store.dispatch(actions.setQuoteToken(quoteTokenWithApprovalContext))
  const { currentToken } = store.getState().tokens
  if (currentToken) {
    gtmService.v3.viewProductDetails(currentToken, quoteToken)
    gtmService.v4.viewProductDetails(currentToken, quoteToken)
  }

  return quoteToken
}

export const setQuoteTokenById = async (
  quoteTokenId: string,
  library: Web3,
  account?: Address | null,
  network?: string
): Promise<TokenV3 | undefined> => {
  let quoteToken = store
    .getState()
    .tokens.tokens.find((token) => token.id === quoteTokenId) as TokenWithApprovalContext
  if (quoteToken) {
    store.dispatch(actions.setQuoteToken(quoteToken))
  } else {
    const response = await getTokenDetails(quoteTokenId)

    if (!response) {
      return
    }

    quoteToken = response as TokenWithApprovalContext
  }

  return setQuoteToken(quoteToken, library, account, network)
}

export const loadCurrentQuoteTokens = async (
  currentToken: TokenWithApprovalContext,
  library: Web3,
  network?: string,
  quoteToken?: TokenWithApprovalContext,
  account?: Address | null
): Promise<{ currentToken: TokenWithApprovalContext; quoteToken?: TokenWithApprovalContext }> => {
  if (currentToken.network !== quoteToken?.network || currentToken.id === quoteToken?.id) {
    quoteToken = (await getQuoteToken(currentToken)) as TokenWithApprovalContext
  }

  if (quoteToken.network === network) {
    const zeroXAllowance = await checkTokenAllowance({
      account,
      tokenFrom: currentToken,
      library,
      spender: TokenSpenders.zeroX,
    })
    const oneInchAllowance = await checkTokenAllowance({
      account,
      tokenFrom: currentToken,
      library,
      spender: TokenSpenders.oneInch,
    })

    currentToken = { ...currentToken, zeroX: zeroXAllowance, oneInch: oneInchAllowance }
  } else {
    currentToken = { ...currentToken, zeroX: undefined, oneInch: undefined }
  }

  setTokenToLocalStorage(currentToken, account)

  if (quoteToken) {
    gtmService.v3.viewProductDetails(currentToken, quoteToken)
    gtmService.v4.viewProductDetails(currentToken, quoteToken)

    if (quoteToken.network === network) {
      const zeroXAllowance = await checkTokenAllowance({
        account,
        tokenFrom: quoteToken,
        library,
        spender: TokenSpenders.zeroX,
      })
      const oneInchAllowance = await checkTokenAllowance({
        account,
        tokenFrom: quoteToken,
        library,
        spender: TokenSpenders.oneInch,
      })
      quoteToken = { ...quoteToken, zeroX: zeroXAllowance, oneInch: oneInchAllowance }
    } else {
      currentToken = { ...currentToken, zeroX: undefined, oneInch: undefined }
    }
  }

  return { currentToken, quoteToken }
}

export const getTopToken = async (): Promise<TokenV3 | undefined> => {
  try {
    const networksConfigById = store.getState().networksConfig.data.sort((a, b) => a.id - b.id)
    const topTokenId = networksConfigById[0]?.most_liquid_tokens[0]?.id
    if (!topTokenId) {
      return
    }

    return await refreshToken(topTokenId)
  } catch (error) {
    console.error('top token error', error)
    throw error
  }
}

export const getTokenDetails = async (tokenIdWithNetwork: string): Promise<TokenV3 | undefined> => {
  try {
    if (!tokenIdWithNetwork) {
      return
    }

    return await refreshToken(tokenIdWithNetwork)
  } catch (error) {
    console.error('token details error', error)
    throw error
  }
}

export const initCurrentToken = async (
  tokenIdWithNetwork: string,
  account?: Address | null
): Promise<TokenV3> => {
  try {
    let tokenId: string | null = getTokenIdFromLocalStorage(account)

    let currentToken: TokenV3 | undefined

    if (tokenIdWithNetwork) {
      tokenId = tokenIdWithNetwork

      const tokenAddress = getTokenAddressFromId(tokenIdWithNetwork) // backward compatibility some poor soul have token without address

      if (!isValidWeb3Address(tokenAddress)) {
        tokenId = null
      }
    }

    if (tokenId) {
      currentToken = await getTokenDetails(tokenId)
    }

    if (!currentToken) {
      currentToken = await getTopToken()
    }
    if (!currentToken) {
      throw new Error('token initialization error')
    }

    return currentToken
  } catch (error) {
    console.error('init token error', error)
    throw error
  }
}

export const diffsBtwTokenIdLists = (tokenIds: string[], tokenIdsSource: string[]): string[] =>
  tokenIds.filter((tokenId) => !tokenIdsSource.some((tokenIdSource) => tokenIdSource === tokenId))

export const getMissingTokenIds = (
  tokenIds: string[],
  storedTokensData: TokenWithApprovalContext[]
): string[] =>
  tokenIds.filter(
    (tokenId) => !storedTokensData.some((storedTokenData) => storedTokenData.id === tokenId)
  )

export const findMissingTokens = (
  tokenIds: Array<{ id: string; network: string }>,
  storedTokenIds: TokenWithApprovalContext[]
): string[] => {
  if (!tokenIds.length) {
    return []
  }

  return tokenIds
    .filter((b) => !storedTokenIds.some((t) => idAndNetworkMatch(b, t)))
    .map((b) => b.id)
}

export const refreshToken = async (id: string): Promise<TokenV3 | undefined> => {
  try {
    const networksConfigState = store.getState().networksConfig

    // TODO: remove after testing #21671
    const availableNetworks = getAvailableNetworks(networksConfigState)
      .filter((network) => network !== 'Ropsten')
      .join(',')

    const body = { ids: [`${id}`], network: availableNetworks }

    const tokens = await fetchAndCacheTokens(body)

    if (tokens.length) {
      store.dispatch(actions.updateTokens(tokens))
    }

    return tokens[0]
  } catch (error) {
    console.error('refresh token error', error)
    throw error
  }
}

export const pickTokenFrom = <T extends TokenWithApprovalContext | undefined>(
  tradeType: TradeType | null = null,
  { currentToken, quoteToken }: { currentToken: T; quoteToken: T }
): T => {
  return tradeType === 'Buy' ? quoteToken : currentToken
}

export const pickTokenTo = <T extends TokenWithApprovalContext | undefined>(
  tradeType: TradeType | null = null,
  { currentToken, quoteToken }: { currentToken: T; quoteToken: T }
): T => {
  return tradeType === 'Buy' ? currentToken : quoteToken
}

export function calculateDeltaUsdPrice({
  fromAmountSelectedCurrency,
  toAmountSelectedCurrency,
}: {
  fromAmountSelectedCurrency: BigNumber
  toAmountSelectedCurrency: BigNumber
}): number {
  return toAmountSelectedCurrency
    .minus(fromAmountSelectedCurrency)
    .dividedBy(fromAmountSelectedCurrency)
    .multipliedBy(100)
    .toNumber()
}

export const getTokensPrices = async (ids: string[]): Promise<TokenPrice[]> => {
  const abortableFetchResponse = await legacyAbortableFetch<{ data: TokenPrice[] }>(
    environment.getDexGuruAPIV2Url(),
    `/tokens/price`,
    {
      init: { method: 'POST', body: JSON.stringify({ ids }) },
    },
    'tokenPriceAbortController'
  ).catch((e: Error) => {
    console.error(`Error getting token prices ${e}`)
    return { response: { data: [] }, isAborted: false }
  })

  return abortableFetchResponse?.response?.data || []
}

const isValidWeb3Address = (address: string): boolean => {
  try {
    const toChecksumToken = Web3.utils.toChecksumAddress(address)
    return Web3.utils.checkAddressChecksum(toChecksumToken)
  } catch {
    return false
  }
}

export const getTopVolumeTokens = async (
  network: string,
  limit = 20,
  fromNum = 0
): Promise<TokenV3[]> => {
  const networksConfigState = store.getState().networksConfig
  const availableNetworks = getAvailableNetworks(networksConfigState).join(',')

  const body = {
    ids: [],
    network: network === 'all' ? availableNetworks : network,
    sort_by: 'volume24hUSD',
    order: 'desc',
    field: 'verified',
    value: 'true',
    from_num: fromNum,
    limit: limit,
  }

  try {
    return await fetchAndCacheTokens(body)
  } catch (error) {
    console.error(`Error getting top volume tokens ${error}`)
    return []
  }
}

export const getTrendingTokens = async (
  network: string,
  tokenIds: string[] = []
): Promise<TrendingTokenV3[]> => {
  const networksConfigState = store.getState().networksConfig
  const availableNetworks = getAvailableNetworks(networksConfigState).join(',')
  const bodyRequest =
    network === 'all'
      ? { ids: tokenIds, network: availableNetworks }
      : { ids: tokenIds, network: network }

  try {
    const { data } = await cachios.post<CacheBodyRequest, CacheResponse<TrendingTokenV2>>(
      `${environment.getDexGuruAPIV2Url()}/tokens/trending`,
      bodyRequest,
      { ttl: TOKEN_CACHE_TTL_SEC }
    )

    const tokens = mapTrendingTokenV2ToV3(data.data)
    return tokens
  } catch (error) {
    console.error(`Error getting trading tokens ${error}`)
    return []
  }
}

export const getGainersTokens = async (
  currency: string,
  network: string,
  limit = 20
): Promise<TokenV3[]> => {
  const networksConfigState = store.getState().networksConfig
  const availableNetworks = getAvailableNetworks(networksConfigState).join(',')
  const networkParams = network === 'all' ? `&network=${availableNetworks}` : `&network=${network}`

  try {
    const { data } = await cachios.get<CacheBodyRequest, CacheResponse<TokenV2>>(
      `${environment.getDexGuruAPIV2Url()}/tokens/top/gainers?currency=${currency}&limit=${limit}&offset=0${networkParams}`,
      { ttl: TOKEN_CACHE_TTL_SEC }
    )

    const tokens = mapTokenV2ToV3(data.data)
    store.dispatch(actions.updateTokens(tokens))
    return tokens
  } catch (error) {
    console.error(`Error getting gainers tokens ${error}`)
    return []
  }
}

export const getLosersTokens = async (
  currency: string,
  network: string,
  limit = 20
): Promise<TokenV3[]> => {
  const networksConfigState = store.getState().networksConfig
  const availableNetworks = getAvailableNetworks(networksConfigState).join(',')
  const networkParams = network === 'all' ? `&network=${availableNetworks}` : `&network=${network}`

  try {
    const { data } = await cachios.get<CacheBodyRequest, CacheResponse<TokenV2>>(
      `${environment.getDexGuruAPIV2Url()}/tokens/top/losers?currency=${currency}&limit=${limit}&offset=0${networkParams}`,
      { ttl: TOKEN_CACHE_TTL_SEC }
    )

    const tokens = mapTokenV2ToV3(data?.data)
    store.dispatch(actions.updateTokens(tokens))
    return tokens
  } catch (error) {
    console.error(`Error getting losers tokens ${error}`)
    return []
  }
}

export const getRecentTokens = async (
  currency: string,
  network: string,
  limit = 20
): Promise<TokenV3[]> => {
  const networksConfigState = store.getState().networksConfig
  const availableNetworks = getAvailableNetworks(networksConfigState).join(',')
  const networkParams = network === 'all' ? `&network=${availableNetworks}` : `&network=${network}`

  try {
    const { data } = await cachios.get<CacheBodyRequest, CacheResponse<TokenV2>>(
      `${environment.getDexGuruAPIV2Url()}/tokens/newly_listed?currency=${currency}&limit=${limit}&offset=0${networkParams}`,
      { ttl: TOKEN_CACHE_TTL_SEC }
    )

    const tokens = mapTokenV2ToV3(data?.data)
    store.dispatch(actions.updateTokens(tokens))
    return tokens
  } catch (error) {
    console.error(`Error getting recent tokens ${error}`)
    return []
  }
}

export const getTokenSummary = async ({
  token,
}: {
  token: TokenWithApprovalContext
}): Promise<TokenProfileSummaryData> => {
  const tokenProfileSummaryUrl =
    process.env.REACT_APP_LP_TOKENS_PROFILE === 'true'
      ? environment.getDexGuruAPIV2Url()
      : environment.getDexGuruAPIV1Url()

  const response = await legacyApiFetch(tokenProfileSummaryUrl, `/tokens/${token.id}/summary`)
  return response as TokenProfileSummaryData
}

export const addToMetaMask = async (library?: Web3, token?: TokenV3): Promise<boolean> => {
  if (!token || !library) {
    return false
  }

  try {
    const provider = library?.currentProvider as AbstractProvider

    if (!provider || !provider.request) {
      return false
    }

    return window.ethereum.request<boolean>({
      method: 'wallet_watchAsset',
      params: {
        // TODO other networks?
        type: 'ERC20',
        options: {
          address: token.address,
          symbol: getMarketDisplayName(token),
          decimals: token.decimals,
          image: token.logoURI,
        },
      },
    })
  } catch (error) {
    console.error('Error adding token to wallet', error)
    return false
  }
}

// TODO: move to API service
// https://app.shortcut.com/dexguru/story/22628
const fetchAndCacheTokens = async (body: CacheBodyRequest): Promise<TokenV3[]> => {
  const { data } = await cachios.post<CacheBodyRequest, CacheResponse<TokenV3>>(
    `${environment.getDexGuruAPIV3Url()}/tokens`,
    body,
    {
      ttl: TOKEN_CACHE_TTL_SEC,
    }
  )
  return data?.data || []
}

export const mapTokenV2ToV3 = (tokensV2: TokenV2[] = []): TokenV3[] => {
  return tokensV2.map((tokenV2) => {
    const tokenV3: TokenV3 = {
      ...tokenV2,
      logoURI: [tokenV2.logoURI || ''],
      symbols: [tokenV2.symbol],
      address: tokenV2.address,
      tokenListsNames: undefined,
      marketCap: undefined,
      marketCapChange24h: undefined,
      marketType: MarketType.token,
      underlyingAddresses: undefined,
    }
    return tokenV3
  })
}

export const mapTrendingTokenV2ToV3 = (tokensV2: TrendingTokenV2[] = []): TrendingTokenV3[] => {
  return tokensV2.map((tokenV2) => {
    const tokenV3: TrendingTokenV3 = {
      ...tokenV2,
      logoURI: [tokenV2.logoURI || ''],
      symbols: [tokenV2.symbol],
    }
    return tokenV3
  })
}
